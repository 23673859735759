
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/homePage/HomePage';
import ProductPage from './pages/productDetailsPage/ProductDetailsPage';
import CartPage from './pages/cartPage/CartPage';


function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/categories/:categoryId" element={<HomePage />} />
          <Route path="/search/:keyword" element={<HomePage />} />
          <Route path="/products/:productId" element={<ProductPage />} />
          <Route path="/cart" element={<CartPage />} />
        </Routes>
      </div>

    </Router>
  );
}

export default App;
