import React from 'react';
import { List } from 'antd';
import { GET_CATEGORIES } from '../../graphQL/queries';
import { useQuery } from '@apollo/client';

interface FilterCategoryCardProps {
  scValue: string | undefined
}

const FilterCategoryCard: React.FC<FilterCategoryCardProps> = ({ scValue }) => {

  const variables = {
    keyword: scValue,
  };
  interface product {
    path: string
    _id: string

  }
  const { loading, error, data } = useQuery(GET_CATEGORIES, {
    variables: variables,
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div style={{ width: '95%' }}>



      <List
        size="small"

        dataSource={data?.GetCategories || []}
        renderItem={(item: product) => <a href={`/categories/${item?._id}`} >
          <List.Item key={item?._id}>{item?.path}</List.Item></a>}
      />

    </div>
  );

};

export default FilterCategoryCard;