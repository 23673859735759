import { gql } from '@apollo/client';

export const GET_PRODUCT = gql`
query GetProduct($id: ObjectId!) {
  GetProduct(_id: $id) {
    name
    brand
    code
    price0
    price01Qty
    price1
    price12Qty
    price2
    quantity
    _id
    description
    category {
      path
    }
  }
} 
`;

export const GET_PRODUCTS_FILTER = gql`
query GetProducts($keyword: String, $categoryId: ObjectId,$skip: Int, $limit: Int) {
  GetProducts(keyword: $keyword, categoryId: $categoryId, skip: $skip, limit: $limit) {
    _id
    name
    price0
    code
    brand
    category {
      _id
      path
    }
  }
}
`;

export const GET_CATEGORIES = gql`
query GetCategories($keyword: String) {
  GetCategories(keyword: $keyword) {
    _id
    path
    isActive
  }
}
`;