////@ts-nocheck
import { useEffect, useState } from 'react';
import { Breadcrumb, Layout, theme, Spin, Empty } from 'antd';
import { Result } from 'antd';
import CardView from '../../components/productCard/card';
//import products from '../../data/data';
import { useQuery } from '@apollo/client';
import { GET_PRODUCTS_FILTER } from '../../graphQL/queries';
import type { PaginationProps } from 'antd';
import { Pagination } from 'antd';

import { useParams } from 'react-router-dom';
import PageLayout from '../../components/pageLayout/PageLayout';

const { Content } = Layout;

interface product {
    name: string
    brand: string
    price0: number
    code: string
    _id: string
    __typename: string
    category: {
        _id: string;
        path: string;
    };
}

const HomePage = () => {

    const { categoryId, keyword } = useParams();
    
    const currentPage: number = parseInt(sessionStorage.getItem('currentPage') ?? '1', 10);
    const [current, setCurrent] = useState(currentPage);

    useEffect(()=>{
        if (categoryId || keyword) {
            sessionStorage.removeItem('currentPage');
            const currentPage: number = parseInt(sessionStorage.getItem('currentPage') ?? '1', 10);
            setCurrent(currentPage);
        }
    },[categoryId,keyword])
    

    /////////////////////////////////search///////////////////////////////////////////////
    const variables = {
        keyword: '',
        skip: (current - 1) * 10,
    };

    const { loading, error, data, refetch } = useQuery(GET_PRODUCTS_FILTER, {
        variables: variables,
    });


    let length: number = 0;
    let maxLength: number = 10;

    if (!loading) {
        length = data?.GetProducts.length;
        // length=10;

    }
    /////////////////////////////categorySearch////////////////////////////////

    

    if (categoryId) {
        refetch({
            categoryId: categoryId,
            skip: 0
        })

    }
    if (keyword) {
        refetch({
            keyword: keyword,
            skip: 0
        })
    }

    //////////////////////////pagination/////////////////////////////////////////////////

    const onChange: PaginationProps['onChange'] = (page) => {

        refetch({
            skip: (page - 1) * 10,
        })
        sessionStorage.setItem('currentPage', JSON.stringify(page));
        setCurrent(page);

    };
    /////////////////////////////////////////////////////////////////////////////////////
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();


    if (!loading) {
        console.log(data);
    }
    console.log(error);

    if (error) {
        return <Result
            status="500"
            title="500"
            subTitle="Sorry, something went wrong."
        />
    }

    return (
        <PageLayout >
            <Layout style={{ padding: '0px 0px 0px' }}>


                <Breadcrumb style={{ margin: '16px 16px', justifyContent: 'space-evenly' }}>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Products</Breadcrumb.Item>
                    {/* <Breadcrumb.Item>App</Breadcrumb.Item> */}
                </Breadcrumb>
                <Content
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: colorBgContainer,
                        borderRadius: borderRadiusLG,
                    }}>
                    {loading ? (
                        <Spin tip="">
                            <div className="content" >
                                {/* Just a sec Products are loading... */}
                            </div>
                        </Spin>
                    ) : (

                        <div>
                            <label style={{ color: 'goldenrod', fontSize: '28px' }}>Products</label>
                            {categoryId ? (
                                <div>
                                    <label style={{ color: 'black', fontSize: '16px', textAlign: 'left' }}>Search results for {data?.GetProducts[0]?.category?.path ?? 'the category you searched '}</label>
                                    <label style={{ color: 'black', fontSize: '18px', textAlign: 'left' }}></label>
                                </div>
                            ) : (
                                <label style={{ color: 'black', fontSize: '18px', textAlign: 'left' }}></label>
                            )}
                            {keyword ? (
                                <div>
                                    <label style={{ color: 'black', fontSize: '16px', textAlign: 'left' }}>Search results for {keyword}</label>
                                    <label style={{ color: 'black', fontSize: '18px', textAlign: 'left' }}></label>
                                </div>
                            ) : (
                                <label style={{ color: 'black', fontSize: '18px', textAlign: 'left' }}></label>
                            )}

                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ display: 'flex', flexWrap: "wrap", justifyContent: 'center', alignItems: 'center' }}>

                                    {!data?.GetProducts.length ? (<div>
                                        <br></br>
                                        <br></br>
                                        <Empty />
                                        <br></br>
                                        <br></br>
                                    </div>) : (data?.GetProducts.map((product: product) => {

                                        return <CardView
                                            key={product._id}
                                            productName={product.name}
                                            price0={product.price0}
                                            brand={product.brand}
                                            _id={product._id}
                                            code={product.code}
                                        />
                                    }))}
                                </div>

                            </div>
                            <br />
                        </div>
                    )}

                </Content>

                <br />
                {maxLength > length ? (
                    <Pagination current={current}
                        pageSize={10}
                        onChange={onChange}
                        total={10 * (current)}
                    />
                ) : (
                    <Pagination current={current}
                        pageSize={10}
                        onChange={onChange}
                        total={10 * (current + 1)}
                    />
                )}

            </Layout>
        </PageLayout>

    );
};

export default HomePage;