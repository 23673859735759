
import { Button, Spin, List, Avatar, InputNumber, Space, Checkbox, Card, notification, Empty } from 'antd';
import { WhatsAppOutlined, CloseCircleTwoTone, CloseOutlined } from '@ant-design/icons';
import { gql, useQuery } from '@apollo/client';
import * as config from '../../config';
import PageLayout from '../../components/pageLayout/PageLayout';
import { useEffect, useState } from 'react';

const CartPage = () => {

    interface productType {
        _id: string;
        brand: string;
        code: string;
        name: string;
        price0: number;
        price01Qty: number;
        price1: number;
        price12Qty: number;
        price2: number;
        quantity: number;
        units: number;
        isSelected: boolean;
    }


    interface TypeCartProductsIdsUnits {
        id: string;
        units: number;
    }

    let cartProductIdsUnits: string | null = localStorage.getItem("cartProductIdsUnits");
    const [existingCartProductIdsUnitsArray, setexistingCartProductIdsUnitsArray] = useState<TypeCartProductsIdsUnits[] | []>(
        cartProductIdsUnits ? JSON.parse(cartProductIdsUnits) as TypeCartProductsIdsUnits[] : []
    );

    const [productsDataArray, setProductsDataArray] = useState<productType[]>([]);
    const [cartTotalValue, setCartTotalValue] = useState<number>(0);

    const [api, contextHolder] = notification.useNotification();

    let query = "query GetProducts {\n";

    if (existingCartProductIdsUnitsArray.length === 0) {
        query = `query GetProducts {
                product0: GetProduct(_id: "00") {
                    _id
                    brand
                    code
                    name
                    price0
                    price01Qty
                    price1
                    price12Qty
                    price2
                    quantity
                }
            }`
    } else {
        existingCartProductIdsUnitsArray.forEach((item, index) => {
            query += `    product${index}: GetProduct(_id: "${item.id}") {\n`;
            query += `        _id\n`;
            query += `        brand\n`;
            query += `        code\n`;
            query += `        name\n`;
            query += `        price0\n`;
            query += `        price01Qty\n`;
            query += `        price1\n`;
            query += `        price12Qty\n`;
            query += `        price2\n`;
            query += `        quantity\n`;
            query += `    }\n`;
        });
        query += "}";
    }

    const GET_PRODUCTS_CART = gql`${query}`;

    const { loading, error, data } = useQuery(GET_PRODUCTS_CART);



    useEffect(() => {
        if (data) {
            const productData: productType[] = Object.values(data);
            const newProductData: productType[] = [];
            productData.forEach(item => {
                const cartItem = existingCartProductIdsUnitsArray.find(cartItem => cartItem.id === item._id);
                const newItem: productType = { ...item, units: cartItem?.units ?? 1, isSelected: true };
                newProductData.push(newItem);
            });

            setProductsDataArray(newProductData);

        }
    }, [data, existingCartProductIdsUnitsArray]);


    useEffect(() => {

        let total = 0;
        productsDataArray.forEach(item => {
            let itemPrice = 0;
            if (item.units > item.price12Qty) {
                itemPrice = item.price2;
            } else if (item.units > item.price01Qty) {
                itemPrice = item.price1;
            } else {
                itemPrice = item.price0;
            }
            if (item.isSelected === true) {
                total += itemPrice * item.units;
            }

        });
        setCartTotalValue(total);
    }, [productsDataArray]);


    if (loading) {
        return (
            <Spin tip="Loading" style={{ marginTop: '20%' }}>
                <div className="content"></div>
            </Spin>
        );
    };

    const sendMessage = () => {
        let mobileNumber = config.whatsappNumber;
        let number = mobileNumber.replace(/[^\w\s]/gi, "").replace(/ /g, "");
        let url = `https://web.whatsapp.com/send?phone=${number}`;

        const products = productsDataArray.filter(item => item.isSelected === true);
        let message = "";
        if (products.length !== 0) {
            products.forEach(item => {

                let itemPrice: number = 0;
                if (item.units > item.price12Qty) {
                    itemPrice = item.price2;
                } else if (item.units > item.price01Qty) {
                    itemPrice = item.price1;
                } else {
                    itemPrice = item.price0;
                }
                // total += itemPrice * item.units;
                message += `
                Product Name: ${item.name}\n
                Product Code: ${item.code}\n
                Brand: ${item.brand}\n
                Quantity: ${item.units}\n
                Price: Rs ${itemPrice}\n
            `;
            })

            url += `&text=${encodeURI(message)}&app_absent=0`;
            window.open(url);

            products.forEach(productItem => {
                removeCartItem(productItem._id);
            })

        } else {
            api.open({
                message: 'Error',
                description:
                    'Select at least one item to checkout',
                icon: <CloseOutlined style={{ color: 'red' }} />,
            });
        }
    }


    const removeCartItem = (itemId: string) => {

        const updatedproductsDataArray = productsDataArray.filter(item => item._id !== itemId);

        const updatedCartProductIdsUnitsArray = existingCartProductIdsUnitsArray.filter(item => item.id !== itemId);
        localStorage.setItem("cartProductIdsUnits", JSON.stringify(updatedCartProductIdsUnitsArray));

        cartProductIdsUnits = localStorage.getItem("cartProductIdsUnits");
        setProductsDataArray(updatedproductsDataArray);
        setexistingCartProductIdsUnitsArray(cartProductIdsUnits ? JSON.parse(cartProductIdsUnits) as TypeCartProductsIdsUnits[] : []);

    };


    const changeCartItemUnitValue = (itemId: string, value: number) => {

        const cartItem: TypeCartProductsIdsUnits | undefined = existingCartProductIdsUnitsArray.find(item => item.id === itemId);
        if (cartItem?.units) {
            cartItem.units = value;
            localStorage.setItem("cartProductIdsUnits", JSON.stringify(existingCartProductIdsUnitsArray));
        }

        const productItemIndex: number = productsDataArray.findIndex(item => item._id === itemId);
        if (productItemIndex !== -1) {
            const updatedProductsDataArray = [...productsDataArray];
            updatedProductsDataArray[productItemIndex] = {
                ...updatedProductsDataArray[productItemIndex],
                units: value
            };

            setProductsDataArray(updatedProductsDataArray);

        };
    }


    const changeIsSelected = (itemId: string, value: boolean) => {

        const productItemIndex: number = productsDataArray.findIndex(item => item._id === itemId);
        if (productItemIndex !== -1) {
            const updatedProductsDataArray = [...productsDataArray];
            updatedProductsDataArray[productItemIndex] = {
                ...updatedProductsDataArray[productItemIndex],
                isSelected: value
            };
            setProductsDataArray(updatedProductsDataArray);
        };
    }


    // if (error) return <p>Error : {error.message}</p>;

    return (
        <PageLayout>
            {error ? (<Empty style={{ margin: '10%' }} description={<h3 style={{ color: 'red' }}>Cart is Empty</h3>} />) : (
                <div>
                    {contextHolder}
                    <br />
                    <div style={{ margin: '1px', borderRadius: '10px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1) ', minHeight: '400px' }}>
                        <br />
                        <br />
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            <div style={{ flex: '3' }}>
                                <List
                                    className="demo-loadmore-list"
                                    loading={false}
                                    itemLayout="horizontal"
                                    style={{ alignItems: 'center', minWidth: '350px' }}
                                    loadMore={false}
                                    dataSource={productsDataArray}
                                    renderItem={(item: productType) => {

                                        let itemPrice: number = 0;
                                        let itemTotalPrice: number = 0;
                                        let pricemessage: string = '';

                                        if (item.units > item.price12Qty) {
                                            itemPrice = item.price2;
                                            itemTotalPrice = (itemPrice * 100 * item.units / 100);
                                            pricemessage = `Unit price is Rs${item.price2} for over ${item.price12Qty} units`
                                        }
                                        else if (item.units > item.price01Qty) {
                                            itemPrice = item.price1;
                                            itemTotalPrice = (itemPrice * 100 * item.units / 100);
                                            pricemessage = `Unit price is Rs${item.price2} for over ${item.price12Qty} units`
                                        }
                                        else {
                                            itemPrice = item.price0;
                                            itemTotalPrice = (itemPrice * 100 * item.units / 100);
                                            pricemessage = `Unit price is Rs${item.price1} for over ${item.price01Qty} units`
                                        }


                                        return (
                                            <List.Item
                                                style={{
                                                    maxWidth: '600px', height: '100px', margin: 'auto', padding: '10px',
                                                    borderRadius: '10px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.0.8) ', marginBottom: '15px',
                                                    display: 'flex', flexWrap: 'wrap', minWidth: '350px'
                                                }}
                                                actions={[
                                                    <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}>
                                                        <Space style={{ minWidth: '178px' }}>
                                                            <InputNumber
                                                                min={1}
                                                                // defaultValue={item.units}
                                                                value={item.units}
                                                                onChange={(value) => {

                                                                    value = value ?? 1;
                                                                    changeCartItemUnitValue(item._id, value);
                                                                }
                                                                }
                                                            />
                                                            <Checkbox defaultChecked={item?.isSelected} onChange={(value) => {


                                                                changeIsSelected(item._id, value.target.checked);
                                                            }
                                                            }>
                                                                Select
                                                            </Checkbox>

                                                            <Button style={{ border: 'none' }} onClick={() => {
                                                                removeCartItem(item?._id);

                                                            }}>
                                                                <CloseCircleTwoTone />
                                                            </Button>
                                                        </Space>

                                                        <Space style={{ minWidth: '178px' }}>

                                                            <div>
                                                                <p style={{ fontSize: '12px' }}>{pricemessage}</p>
                                                                <p>Rs: {itemPrice} x {item.units} = Rs: {itemTotalPrice.toFixed(2)} </p>

                                                            </div>
                                                            {/* <Button type="primary" style={{ color: 'black' }} onClick={() => { }}>Add to Cart</Button> */}
                                                        </Space>
                                                    </div>
                                                ]}>
                                                <List.Item.Meta
                                                    avatar={<div><Avatar src={config.BASE_URL_API_IMG + '/' + item._id + '/img1'} /></div>}
                                                    title={<a href={`/products/${item._id}`}><p style={{ fontSize: '13px' }}>{item.name}: </p><p style={{ fontSize: '13px' }}>{item.code}</p></a>}
                                                />
                                            </List.Item>
                                        )
                                    }}
                                />

                            </div>
                            <div style={{ flex: '2', display: 'flex', justifyContent: 'center' }}>
                                <Card title={<div>
                                    <p>{productsDataArray.filter(item => item.isSelected === true).length} items have been selected</p>
                                </div>} bordered={false} style={{ width: '100%', height: '200px', background: 'transparent' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <h5>Total: Rs {cartTotalValue}</h5>

                                        <Button type="primary" shape="round" icon={<WhatsAppOutlined />} size='large'
                                            style={{ color: 'black', marginTop: '10px' }} onClick={sendMessage}>
                                            Checkout
                                        </Button>
                                    </div>
                                </Card>

                            </div>
                        </div>
                        <br />
                    </div>
                </div>)}
        </PageLayout>
    );
};

export default CartPage;
