import { useState } from 'react';
import { Card } from 'antd';
import * as config from '../../config';

const { Meta } = Card;

interface ProductProps {
 
  productName: string;
  price0: number;
  brand:string;
  _id:string;
  code:string;
}

const CardView = (
  {
    
    productName,
    price0,
    brand,
    _id,
    code

  }: ProductProps) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <Card
      hoverable
      style={{ width: 220, height: 375, margin: '10px' }}
      cover={<a href={`/products/${_id}`}><img alt='404 Not Found' style={isHovered ? { width: 220, height: 260, opacity: 0.9} : { width: 220, height: 280 }} src={config.BASE_URL_API_IMG+'/'+_id+'/img1'} /></a>}

      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Meta title={<p style={{fontSize:'15px', marginTop:'0px'}}>{productName}</p>} /> {/* description="www.instagram.com" */}

      {isHovered ? (
        <div style={{ color: 'green', fontSize: '14px' }}>
          <label htmlFor="price_value">Price: Rs </label>
          <label style={{ color: 'green', fontSize: '15px' }} className="price_value">{price0}</label>
          <br />
          
          <label style={{ color: 'black', fontSize: '13px', marginRight: '10px' }} >Code:</label>
          <label style={{ color: 'black', fontSize: '13px', marginRight: '10px' }} >{code}</label>
          {/* <WhatsAppOutlined></WhatsAppOutlined> */}
          
        </div>
      ) : (
        <div style={{ color: 'green', fontSize: '14px' }}>
          <label htmlFor="price_value">Price: Rs </label>
          <label style={{ color: 'green', fontSize: '15px' }} className="price_value">
            {price0}
          </label>

        </div>
      )}


    </Card>
  );
};
export default CardView;