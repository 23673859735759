import React from 'react';
import { Avatar, List} from 'antd';
import {  GET_PRODUCTS_FILTER } from '../../graphQL/queries';
import { useQuery } from '@apollo/client';
import * as config from '../../config';


interface FilterProductCardProps {
    spValue: string;
  }
  
  const FilterProductCard: React.FC<FilterProductCardProps> = ({ spValue }) => {
    const variables = {
      keyword: spValue, 
    };
    interface product{
        name:string
        
        brand:string
        price0:number
        _id:string
        __typename:string
      }
  
    const { loading, error, data} = useQuery(GET_PRODUCTS_FILTER, {
      variables: variables,
    });
  
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;
  
    return (
      <div style={{ width: '95%' }}>
        
        <List
          itemLayout="horizontal"
          dataSource={data?.GetProducts || []} 
          renderItem={(item:product, index) => (
            <List.Item style={{ display: 'flex', alignContent: 'flex-start', grid: '3' }}>
              <List.Item.Meta
                avatar={<Avatar src={config.BASE_URL_API_IMG+'/'+item._id+'/img1'} /> }
                title={<a href={`/products/${item._id}`}>{item.name}</a>}
                description={item.brand}
              />
              <div style={{fontSize:'12px'}}>price=Rs.{item.price0}</div>
            </List.Item>
          )}
        />
      </div>
    );
  };
  
  export default FilterProductCard;