import React from 'react';
import FilterCategoryCard from './filterCategoryCard';
import FilterProductCard from './filterProductCard';

interface FilterCardViewProps {
  style?: React.CSSProperties,
   searchValue?: string
}  //#E2F8F8

const FilterCardView = React.forwardRef<HTMLDivElement, FilterCardViewProps>(({ style, searchValue}, ref) => (

  <div ref={ref} style={{ width: '50%', maxHeight: '400px', zIndex: 1, borderRadius: '10px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#e8f3ff', overflowY: 'scroll', padding: '10px', ...style }}>  

    
    <label style={{ textAlign: 'left', fontWeight: 'bold' }}>Suggested Category</label>
    <FilterCategoryCard scValue={searchValue?.toString() ?? ''}/>
    
    <label style={{ textAlign: 'left', fontWeight: 'bold' }}>Suggested Products</label>
    <FilterProductCard spValue={searchValue?.toString() ?? ''}/>
  </div>
));

export default FilterCardView;
