export const URL_API_GQL = "https://multikart-server.fly.dev/graphql";
// export const URL_API_UPLOADS_IMG = "https://multikart-server.fly.dev/uploads/img";
export const BASE_URL_API_IMG = 'https://multikart-server.fly.dev/uploads/img/products';

// export const URL_API_GQL = "http://localhost:8080/graphql";
// export const URL_API_UPLOADS_IMG = "http://localhost:8080/uploads/img";

// export const URL_API_GQL = "http://155.248.246.152:8080/graphql";


// export const URL_API_GQL = 'http://155.248.246.152:8080/graphql';
// export const BASE_URL_API_IMG = 'http://155.248.246.152:8080/uploads/img/products';
// export const BASE_URL_API_IMG = 'http://155.248.246.152:8080/uploads/img/products/65b7c09791515cc290ddb613/img4';

export const whatsappNumber = "+9477777777";