
import { useEffect, useState, useRef, ReactNode } from 'react';
import { Layout } from 'antd';
import { Input } from 'antd';
import FilterCardView from '../filterCard/filterCardView';
import { useNavigate } from 'react-router-dom';
import cart from '../../img/cart.svg';

const { Search } = Input;
const { Header, Footer } = Layout;

interface WrapperLayoutProps {
  children: ReactNode | undefined,
}


const PageLayout = ({ children }: WrapperLayoutProps) => {

  const [showFilterCard, setShowFilterCard] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const navigate = useNavigate();
  const cartItemCount = 0;

  useEffect(() => {

    if (searchVal.length > 2) {
      setShowFilterCard(true);

    }
    else {
      setShowFilterCard(false);
    }
  }, [searchVal]);

  const filterCardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {

    const handleDocumentClick = (event: MouseEvent) => {
      if (filterCardRef.current && !filterCardRef.current.contains(event.target as Node)) {
        setShowFilterCard(false);
      }

    }
    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  const headerStyle = {
    position: 'sticky' as const,
    top: 0,
    zIndex: 1,
    backgroundSize: 'cover',
  };

  return (
    <Layout style={{ padding: '0px 0px 0px', maxWidth: '100%', minWidth: '450px' }}>

      <Header style={headerStyle}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

          <div style={{ flex: '1', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
            <a href="/" onClick={() =>
              sessionStorage.removeItem('currentPage')
            }>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h1 style={{ color: 'blue', fontSize: '28px', margin: '0' }}>E</h1>
                <h1 style={{ color: 'red', fontSize: '28px', margin: '0' }}>matrix</h1>
              </div>
            </a>
          </div>
          <div style={{ flex: '2' }}></div>

          <div style={{ flex: '7' }}>
            <div className="demo-logo" style={{ width: '100%' }}>
              <Search
                placeholder="Search anything..."
                onChange={(e) => setSearchVal(e.target.value.toLowerCase())}
                style={{ width: '100%', marginTop: '15px', borderColor: 'blue', borderWidth: '1px', borderRadius: '10px' }}
                onSearch={() => {
                  if (searchVal) {
                    navigate(`/search/${searchVal}`);
                  }
                }}
              
                enterButton
              />
              {showFilterCard && (
                <div>
                  <FilterCardView
                    ref={filterCardRef}
                    style={{ position: 'absolute', top: '100%', right: '15' }}
                    searchValue={searchVal}
                  />
                </div>
              )}
            </div>
          </div>
          <div style={{ flex: '1' }}></div>

          <div style={{ display: 'flex', justifyContent: 'flex-end', flex: '2', textAlign: 'right' }}>
            <a href='/cart' style={{ position: 'relative', display: 'inline-block' }}>
              <img src={cart} alt="" style={{ width: '40px', height: '40px' }} />
              {cartItemCount > 0 && (
                <label
                  style={{
                    width: '18px',
                    height: '18px',
                    borderRadius: '50%',
                    top: '-8px',
                    right: '-8px',
                    position: 'absolute',
                    backgroundColor: 'red',
                    color: 'white',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '12px',
                  }}
                >
                  {cartItemCount}
                </label>
              )}
            </a>
          </div>
        </div>
      </Header>


      <Layout style={{ height: '100%' }}>
        <Layout style={{ padding: '0 14px 14px', minHeight: '550px' }} >
          {[children]}

        </Layout>
      </Layout>
      <Footer>Ematrix innovation ©{new Date().getFullYear()} developed by Scrum Experts</Footer>
    </Layout>
  );
};



export default PageLayout;